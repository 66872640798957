/* footer */
.footer .heading_3 {
    margin-bottom: 20px;
    font-size: 16px;
}

.footer_content_wrap {
    border-top: 1px solid #fff;
    margin-top: 50px;
    padding: 50px 0;
}

.footer_content_main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-top: 50px;
}

.footer_logo_wrap {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 20px;
}

.approve_img {
    height: 28px;
    max-width: 100%;
}

.footer_subtitle {
    font-family: "Givonic", sans-serif;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    /* margin-top: 20px; */
    color: #fff;
    text-decoration: blink;
}

.footer_text {
    font-family: "Givonic", sans-serif;
    font-size: 17px;
    font-weight: 400;
    display: block;
    margin: 0;
    margin-top: 5px;
}

.footer_email_wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.footer_bottom {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    gap: 5%;
}

.footer_img {
    height: 100px;
}

.footer_img_link {
    display: inline-block;
}

.footer_bottom_text {
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    display: block;
    margin: 0;
}

.footer_link {
    color: #fff;
    text-decoration: none;
    transition: .3s cubic-bezier(0, -0, .3, 2);
}

.footer_link:hover {
    transform: translate3d(15px, 0, 0);
    text-decoration: underline;
}

@media only screen and (max-width: 992px) {
    .footer_content_colunm:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: span col4-start;
        grid-row-start: 1;
        grid-row-end: span 2;
    }

    .footer_content_main {
        grid-gap: 50px;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .footer_logo_wrap {
        grid-template-columns: 1fr;
    }

    .footer_content_main {
        grid-gap: 50px;
        grid-template-columns: 1fr;
    }

    .footer_content_colunm:nth-child(2) {
        grid-column-end: 1;
        grid-row-end: 1;
    }

    .footer_email_wrap {
        grid-template-columns: 1fr;
    }

    .footer_bottom {
        margin-top: 50px;
        gap: 10px;
        flex-flow: column;
    }

    .footer_content_colunm {
        width: 90%;
    }
}