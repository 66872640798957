/* About Us */
.about_us {
    /* height: 190vw; */
    /* overflow: hidden; */
    height: 100vh;
    display: flex;
    /* left: 0; */
}

/* section {
    width: 100%;
    height: 100%;
    position: relative;
  } */
/* .about_us .section.horizontal .pin-wrap, */
.about_us .animation-wrap {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;
    height: 100vh;
}

.aboutItem {
    /* aspect-ratio: 16/9; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: 100% 100%;
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
}

.about_us .animation-wrap {
    background-image: url(../../images/about1.png), url(../../images/about2.png);
    background-position: 50vw top, 151vw top;
    background-size: 100vw 100vh;
    background-repeat: no-repeat, repeat-y;
}

/* .aboutItem:nth-child(1){
    background-image: url(../../images/about1.png);
    background-position: 50vw top;
    background-repeat: no-repeat;
} */
/* .aboutItem:nth-child(2){
    background-image: url(../../images/about1.png), url(../../images/about2.png);
    background-position: -50vw top, 51vw top;
    background-repeat: no-repeat;
} */
/* .aboutItem:nth-child(3){
    background-image: url(../../images/about2.png);
    background-position: -49vw top;
    background-repeat: no-repeat;
} */
/* .what_we_do{
    background-image: url(../../images/about_us2.jpg);
} */
/* .how_we_do{
    background-image: url(../../images/about1.png);
} */
.about_us_content_wrap {
    max-width: 980px;
    width: 100%;
    margin-top: 15%;
}


@media only screen and (max-width: 1100px) {
    .about_us_content_wrap {
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        margin-top: 15%;
    }

    .about_us,
    .about_us .animation-wrap {
        height: auto;
        position: relative;
        flex-flow: column;
        background: transparent;
    }

    .aboutItem {
        position: relative;
        z-index: 1;
        min-height: auto;
        padding-top: 120px;
    }

    .animation-wrap::before {
        position: absolute;
        content: '';
        top: 25%;
        left: 50%;
        width: 90vh;
        height: 60vh;
        transform: translate(-50%, -50%) rotate(90deg);
        background-image: url(../../images/about1.png);
        background-size: contain;
        background-repeat: no-repeat;
    }

    .animation-wrap::after {
        position: absolute;
        content: '';
        top: 80%;
        left: 50%;
        width: 90vh;
        height: 60vh;
        transform: translate(-50%, -50%) rotate(90deg);
        background-image: url(../../images/about2.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 768px) {
    .about_us_content_wrap {
        max-width: 550px;
        width: 90%;
        margin: 0 auto;
        margin-top: 15%;
    }

}