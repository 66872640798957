.contact {
    background-image: url(../../images/contact.jpg);
    background-size: 100% 100%;
    padding-top: 150px;
    min-height: 100vh;
}

.contact_form {
    margin-top: 150px;
    width: 90%;
    position: relative;
}

.contact_form span {
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 300;
    font-size: 42px;
    margin-top: 70px;
    transition: all .5s ease-in-out;
}

.contact_form .form_input {
    font-size: 28px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    max-width: 300px;
    width: 100%;
    color: #fff;
}

.contact_form .form_input[name="email"] {
    max-width: 500px;
    width: 100%;
}

.contact_form .form_input:focus {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: 1px solid #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.from_submit {
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    font-size: 25px;
    margin-top: 70px;
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all .5s ease-in-out;
}


.from_submit:focus {
    outline: none;
}

.sending span,
.sending .from_submit{
    filter: blur(5px);
    opacity: 0;
}

.status_modal {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.modal_close {
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    font-size: 25px;
    margin-top: 70px;
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
}

.email_status{
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin: 0;
}
/* .success{
    color: #38CB89;
}
.error{
    color: #FF5630;
}
.invert .success{
    color: #9b45f1;
}
.invert .error{
    color: #38CB89;
} */

@media only screen and (max-width: 1100px) {
    .contact_form {
        margin-top: 100px;
    }
    .contact_form span{
        font-size: 30px;
        line-height: 40px;
    }
    .contact_form .form_input{
        max-width: 250px;
    }
    .contact_form .form_input[name="email"]{
        max-width: 350px;
    }
}
@media only screen and (max-width: 992px) {
    .contact_form {
        margin: 0 auto;
        margin-top: 50px;
    }
    .contact_form span {
        font-size: 28px;
    }
    .contact_content_wrap .heading_1{
        max-width: 90%;
        margin: 0 auto;
    }
    .contact_form .form_input{
        max-width: 150px;
        font-size: 20px;
    }
    .contact_form .form_input[name="email"]{
        max-width: 200px;
    }
    .email_status {
        font-weight: 500;
        font-size: 28px;
    }
}
@media only screen and (max-width: 600px) {
    .contact{
        background-size: auto 100%;
        background-position: bottom center;
    }
    .contact_form span {
        font-size: 20px;
        line-height: 32px;
    }
    .from_submit {
        font-family: 'PT Mono', monospace;
        font-weight: 400;
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    .contact_form .form_input {
        max-width: 130px;
        font-size: 16px;
    }
    .email_status {
        font-weight: 500;
        font-size: 22px;
    }
    .modal_close {
        font-size: 18px;
    }
}