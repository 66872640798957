 /* Services */
.service{
    background-image: url(../../images/service.png);
    background-position: left 40vh;
    background-size: 100vw 100vh;
    background-repeat: no-repeat, repeat-x;
}
.service_content_wrap_design{
    /* min-width: 100vw; */
    min-height: 100vh;
    /* background-image: url(../images/service_design.jpg); */
    background-size: 100% 100%;
    padding-top: 150px;
}
.accordion_title{
    font-family: "normalidad-ultraextended",sans-serif;
    font-weight: 500;
    font-size: 35px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
    display: block;
    cursor: pointer;
    padding-bottom: 20px;
}
.service_content_wrap{
    display: flex;
}
.service_content_wrap_design .heading_1{
    font-size: 58px;
    flex-basis: 35%;
}
.service_content_wrap_design .paragraph_2{
    letter-spacing: 1px;
    line-height: 30px;
}
.service_content_wrap_design .service_content{
    flex-basis: 60%;
}
.accordion_title .plus_icon,.accordion_title .minus_icon{
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    font-size: 48px;
}
.accordion_title .plus_icon{
    display: inline-block;
}
.accordion_title .minus_icon{
    display: none;
}
.service_design_radio{
    display: none;
}
.accordion_details{
    overflow: hidden;
    height: 0;
    transition: all .4s ease-in-out;
}
.accordion_details .paragraph_2{
    transform: translateY(100%);
    opacity: 0;
    transition: all .4s ease-in-out;
}
.service_design_radio:checked ~ .accordion_details{
    height: auto;
}
.service_design_radio:checked ~ .accordion_details .paragraph_2{
    overflow: hidden;
    transform: translateY(0);
    opacity: 1;
    height: auto;
    margin-top: 0;
}
.service_design_radio:checked ~ .accordion_title .plus_icon{
    display: none;
}
.service_design_radio:checked ~ .accordion_title .minus_icon{
    display: inline-block;
}
.service_content_wrap_develop{
    /* min-width: 100vw; */
    min-height: 100vh;
    /* background-image: url(../images/service_develop.jpg); */
    background-size: 100% 100%;
    padding-top: 150px;
}
.service_dev{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.service_dev_item{
    padding: 15px;
    border: 1px solid #fff;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    transition: all .5s ease;
}
.service_dev_item:hover{
    transform: scale(1.2);
    background: rgb(0 0 0 / 60%);
    z-index: 1;
}
.service_dev:hover > *:not(:hover){
    opacity: 0.3;
}
.service_dev_item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 20%);
    filter: blur(40px);
}
.service_dev_item .paragraph_3{
    letter-spacing: 1px;
    line-height: 20px;
    position: relative;
}
.service_dev_item .heading_3{
    position: relative;
}
.service_content_wrap_develop .heading_1{
    font-size: 58px;
    flex-basis: 35%;
    position: relative;
}
.service_content_wrap_develop .service_content{
    flex-basis: 60%;
}



@media only screen and (max-width: 1100px) {
    .service_content_wrap_design .heading_1,
    .service_content_wrap_develop .heading_1{
        font-size: 36px;
    }
    .accordion_title .plus_icon, .accordion_title .minus_icon{
        font-size: 36px;
        margin-left: 10px;
    }
    .accordion_title{
        font-size: 28px;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .service_content_wrap_design .paragraph_2 {
        letter-spacing: 1px;
        line-height: 24px;
        font-size: 18px;
    }
}
@media only screen and (max-width: 768px) {
    .service_content_wrap{
        flex-flow: column;
        gap: 40px;
        max-width: 90%;
        margin: 0 auto;
    }
    .service_dev_item:hover{
        transform: inherit;
        background: transparent;
    }
    .service_dev:hover > *:not(:hover){
        opacity:1;
    }
}

@media only screen and (max-width: 600px) {
    .service_content_wrap_design .heading_1,
    .service_content_wrap_develop .heading_1{
        font-size: 26px;
    }
    .accordion_title .plus_icon, .accordion_title .minus_icon{
        font-size: 26px;
    }
    .accordion_title{
        font-size: 18px;
    }
    .service_content_wrap_design .paragraph_2 {
        letter-spacing: 1px;
        line-height: 22px;
        font-size: 18px;
    }
    .service_dev{
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
    .service {
        /* background: transparent; */
        background-position: top center;
        background-size: auto 80%;
        background-repeat: no-repeat;
    }
    /* .service::before {
        position: absolute;
        content: '';
        top: 30%;
        left: 50%;
        width: 100vh;
        height: 66vh;
        transform: translate(-50%, -50%) rotate(90deg);
        background-image: url(../../images/service.png);
        background-size: contain;
        background-repeat: no-repeat;
    } */
}