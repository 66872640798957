.case_study {
    /* background-image: url(../../../images/WWApp_CaseStudy_bg.png);
    background-size: 100% 100%;
    padding-top: 150px;
    min-height: 100vh; */
    position: relative;
}

.case_study_content_wrap,
.fixed_content_wrapper .wrapper {
    margin-top: 60px;

    padding-top: 150px;
    min-height: 100vh;
    background-image: url(../../../images/WWApp_CaseStudy_bg.png);
    background-size: 100% 100%;
}

.case_study_container {
    display: flex;
}

.case_study_container .heading_2 {
    margin: 30px 0;
}

.case_study_small_text {
    color: #FFF;
    font-size: 15px;
    font-family: 'PT Mono', monospace;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 50px; */
}

.case_study_text_content {
    max-width: 448px;
    width: 100%;
}

.case_study_text_content .paragraph_1 {
    margin: 45px 0;
    /* display: inline-block; */
}

.details_table {
    list-style: none;
    padding: 0;
    margin-top: 45px;
}

.details_table_text {
    color: #FFF;
    font-size: 15px;
    font-family: 'PT Mono', monospace;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    min-width: 180px;
    display: inline-block;
    margin: 0;
    margin-bottom: 12px;
}

.store_icons {
    margin-top: 15%;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

/* .store_icon:last-child {
    margin-left: 25px;
} */

.case_study_image_container {
    width: 100%;
    text-align: center;
}

.case_study_header_img {
    max-width: 420px;
    width: 100%;
}

.challenges_wwapp_img {
    max-width: 550px;
    width: 100%;
    margin-top: 70px;
}

.case_study_content_wrap .heading_2 {
    margin: 30px 0 40px 0;
}

.stack_icons {
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.stack_icons li {
    display: inline-block;
    margin-right: 35px;
    vertical-align: middle;
}

.key_features_title {
    font-family: "Givonic", sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.fixed_content_wrapper {
    position: relative;
}

.fixed_content_wrapper .wrapper {
    height: 100vh;
    width: 100%;
}

.fixed_content .get_in_touch_link {
    color: #fff;
    text-decoration: none;
}


.fixed_content_wrapper .fixed_content {
    width: 100%;
    padding-top: 150px;
}

/* .fixed_content .case_study_text_content {
    flex-basis: 60%;
    margin-right: auto;
} */

/* .fixed_content .challenges_wwapp_img {
    flex-basis: 25%;
    max-width: 25vw;
    height: auto;
} */

@media only screen and (max-width: 1100px) {
    .case_study_container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .case_study_header_img {
        max-width: 180px;
        margin-top: 5%;
    }

    .store_icons {
        margin-top: 10%;
    }

    .case_study_content_wrap {
        margin-top: 0;
        padding-top: 100px;
    }

    .big_link_text {
        font-size: 20px;
    }

    .fixed_content_wrapper .fixed_content {
        max-width: 650px;
        width: 100%;
        margin: 50px auto;
        padding: 0;
    }

    .case_study_text_content {
        max-width: 650px;
    }

    .case_study_content_wrap,
    .fixed_content_wrapper .wrapper {
        padding-top: 50px;
    }

    /* .case_study_image_container {
        text-align: left;
    } */

    .challenges_wwapp_img {
        max-width: 350px;
        width: 100%;
        margin-top: 30px;
    }

    .fixed_content_wrapper .wrapper {
        height: auto;
    }

    .case_study_container .heading_2 {
        margin: 25px 0;
    }
}

@media only screen and (max-width: 768px) {
    .challenges_wwapp_img {
        max-width: 250px;
        width: 100%;
        margin-top: 30px;
    }

    .case_study_container .heading_2 {
        margin: 20px 0;
    }

    .case_study_header_img {
        margin-top: 80px;
    }

    .case_study_content_wrap,
    .fixed_content_wrapper .wrapper {
        padding: 0;
    }

    .fixed_content_wrapper .wrapper {
        background-image: none;
    }

    .fixed_content {
        background-image: url(../../../images/WWApp_CaseStudy_bg.png);
        background-size: 100% 100%;
    }
}


@media only screen and (max-width: 550px) {
    .case_study_text_content .paragraph_1 {
        margin: 30px 0;
    }
}