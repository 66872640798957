/* Font setup */
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://use.typekit.net/zub0wtv.css');

@font-face {
    font-family: 'Givonic';
    src: url('../../fonts/Givonic-Regular.eot');
    src: url('../../fonts/Givonic-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Givonic-Regular.woff2') format('woff2'),
        url('../../fonts/Givonic-Regular.woff') format('woff'),
        url('../../fonts/Givonic-Regular.ttf') format('truetype'),
        url('../../fonts/Givonic-Regular.svg#svgFontName') format('svg');
}

@font-face {
    font-family: 'Givonic-SemiBold';
    src: url('../../fonts/Givonic-SemiBold.eot');
    src: url('../../fonts/Givonic-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Givonic-SemiBold.woff2') format('woff2'),
        url('../../fonts/Givonic-SemiBold.woff') format('woff'),
        url('../../fonts/Givonic-SemiBold.ttf') format('truetype'),
        url('../../fonts/Givonic-SemiBold.svg#svgFontName') format('svg');
}



/* Default setup */
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    transition: 0.3s ease-out;
    max-width: 100%;
    width: 100%;
    background-color: #000;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

.app {
    filter: inherit;
    transition: 1s ease-out;
}

.invert {
    filter: invert();
}

.invert .no_invert {
    filter: invert();
}


.container {
    max-width: 1310px;
    width: 96%;
    padding: 0 15px;
    margin: 0 auto;
}

.heading_1 {
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 900;
    font-size: 48px;
    line-height: 55px;
    margin: 0;
}

.heading_2 {
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

.heading_3 {
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.paragraph_1 {
    font-family: "Givonic", sans-serif;
    font-size: 30px;
}

.paragraph_2 {
    font-family: "Givonic", sans-serif;
    font-size: 20px;
    line-height: 26px;
}

.paragraph_3 {
    font-family: "Givonic", sans-serif;
    font-size: 15px;
}

.big_link_text {
    color: #FFF;
    font-size: 30px;
    font-family: "normalidad-ultraextended", sans-serif;
    font-weight: 900;
    text-decoration: none;
}

.wrapper {
    background-color: #000;
    color: #fff;
    width: auto;
    overflow: hidden !important;
    margin: 0;
}

@media only screen and (max-width: 1100px) {
    .heading_1 {
        font-size: 36px;
        line-height: 42px;
    }

    .heading_2 {
        font-size: 24px;
    }

    .paragraph_1 {
        font-size: 22px;
    }

    .paragraph_2 {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 768px) {
    .heading_1 {
        font-size: 26px;
        line-height: 36px;
    }

    .paragraph_1 {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 30px;
    }
}