.header{
    background: #000 url("../../images/hero-bg.jpg") no-repeat center center;
    background-size: cover;
    min-height: 100vh;
    padding-top: 50px;
    position: relative;
}

.hero{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    width: 100%;
}
.hero_heading{
    font-family: "normalidad-ultraextended",sans-serif;
    font-weight: 900;
    font-size: 58px;
    margin: 0;
}
.hero_paragraph{
    font-family: "Givonic",sans-serif;
    font-size: 28px;
    margin-top: 44px;
}

/* Responsive */
@media only screen and (max-width: 1100px) {
    .hero_heading {
        font-size: 36px;
    }
    .hero_paragraph {
        font-size: 22px;
        line-height: 35px;
    }
}
@media only screen and (max-width: 992px) {
    .hero_heading {
        line-height: 50px;
    }
    .hero_paragraph {
        margin-top: 20px;
    }
    .header{
        background-size: auto 80%;
    }
}