body{
    background-color: #fff;
}
.privecy_policy_paragraph{
    font-family: Givonic, sans-serif;
    font-size: 22px;
    font-weight: 400;
}
.privecy_policy_heading {
    font-family: Givonic, sans-serif;
    font-size: 32px;
    font-weight: 700;
}
.privecy_policy_logo{
    display: block;
    text-align: center;
    margin: 30px 0;
}

@media only screen and (max-width: 768px) {
    .privecy_policy_paragraph{
        font-size: 16px;
    }
    .privecy_policy_heading {
        font-size: 20px;
    }
}