.global_settings {
    position: relative;
    z-index: 10;
}

.global_settings .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}

.top_settings {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
}

.bottom_settings {
    position: fixed;
    top: calc(100vh - 50px);
    left: 0;
    width: 100%;
}

.contactButton_top {
    position: fixed;
    top: 0;
    color: #fff;
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    letter-spacing: 1.5px;
    display: inline-block;
    transform: rotate(270deg) translateY(-100px) translateX(-50px);
    transform-origin: right;
    text-decoration: none;
    transition: all .5s ease;
}

.invert_wrap {
    position: absolute;
    top: 0;
    right: 20px;
}

.invert_check {
    display: none;
}

.invert_icons {
    cursor: pointer;
    width: 70px;
    height: 35px;
    padding: 5px 8px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #fff;
    background-color: transparent;
    margin-top: 7px;
    border-radius: 20px;
    position: relative;
}

.invert_icons .day svg,
.invert_icons .night svg {
    height: 20px;
    fill: #fff;
}

.invert_icons:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 25px;
    top: 3px;
    left: 38px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 20px;
    transition: all .3s ease-in-out;
}

.invert_check:checked~.invert_icons:after {
    left: 4px;
}

.scroll_down {
    position: fixed;
    z-index: 9;
    top: calc(100vh - 50px);
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all .8s ease-in-out;
}

.breadcrumb {
    /* transform: rotate(270deg) translateX(370px);
    right: 20px; */
    transform: rotate(270deg) translateX(530px);
    right: 10px;
    transform-origin: right;
    position: absolute;
    width: 470px;
}

.breadcrumb_item {
    text-decoration: none;
    color: #fff;
    font-family: 'PT Mono', monospace;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1.5px;
    display: inline-block;
    text-transform: uppercase;
    padding-right: 10px;
}

.logo-img {
    max-width: 160px;
    width: 100%;
}

.nav,
.nav_mobile {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 9;
}

.nav:before,
.nav_mobile:before {
    position: absolute;
    content: '';
    height: 150px;
    width: 100%;
    background: linear-gradient(180deg, #000000, rgb(0 0 0 / 65%), #00000000);
    left: 0;
    top: -50px;
}

.nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
    transform: translateX(50%) translateX(-100px);
}

.menu {
    list-style: none;
    visibility: hidden;
    display: flex;
    gap: 75px;
    transition: all 1s ease;
    margin-right: 130px;
    opacity: 0;
    padding: 0;
}

.menu li {
    display: inline-block;
}

.menu_item {
    font-family: "Givonic", sans-serif;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
}

.lets_talk_menu {
    font-family: 'PT Mono', monospace;
    font-weight: 400;
}

.cursor {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    transition: .3s cubic-bezier(0, -0, .3, 2);
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 999999999;
    mix-blend-mode: difference;
    will-change: transform;
}

.cursor svg {
    fill: #fff;
}

.cursor.click {
    width: 30px;
    height: 30px;
    margin: 10px 0 0 10px;
}

.nav_active .nav .container {
    transform: translateX(0);
    margin-left: auto;
}

.nav_active .menu {
    visibility: visible;
    opacity: 1;
}

.nav_active .contactButton_top {
    transform: rotate(270deg) translateY(-100px) translateX(150%);
}

.nav_active .scroll_down {
    transform: translateY(200px);
    animation: inherit;
}


.scroll {
    background: #4e5559 linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
    position: relative;
    width: 27px;
    height: 42px;
    display: block;
    border-radius: 100px;
    background-size: 100% 200%;
    -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
    animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}

.scroll:before,
.scroll:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.scroll:before {
    width: 25px;
    height: 40px;
    background-color: #000;
    border-radius: 100px;
}

.scroll:after {
    background-color: #ffffff;
    width: 5px;
    height: 8px;
    border-radius: 3px;
    -webkit-animation: trackBallSlide 5s linear infinite;
    animation: trackBallSlide 5s linear infinite;
}

/* hamburger icon menu icon */
.menu_bar {
    position: fixed;
    top: 50px;
}

.menu_bar_icon {
    color: #fff;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    outline: none;
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;
}

.menu_bar_icon svg {
    width: 64px;
    height: 48px;
    top: -6px;
    left: -14px;
    stroke: #fff;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    display: block;
    position: absolute;
}

.menu_bar_icon svg path {
    transition:
        stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s),
        stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
    stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
    stroke-dashoffset: var(--offset, 126px);
    transform: translateZ(0);
}

.menu_bar_icon svg path:nth-child(2) {
    --duration: 0.7s;
    --easing: ease-in;
    --offset: 100px;
    --array-2: 65px;
}

.menu_bar_icon svg path:nth-child(3) {
    --offset: 133px;
    --array-2: 107px;
}

.menu_bar_icon.active svg path {
    --offset: 57px;
}

.menu_bar_icon.active svg path:nth-child(1),
.menu_bar_icon.active svg path:nth-child(3) {
    --delay: 0.15s;
    --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
}

.menu_bar_icon.active svg path:nth-child(2) {
    --duration: 0.4s;
    --offset: 2px;
    --array-1: 1px;
}

.menu_bar_icon.active svg path:nth-child(3) {
    --offset: 58px;
}


/* Mobile Menu  */
.menu_mobile_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: #000;
    text-align: center;
    transform: translateX(105%);
}

.nav_mobile .logo {
    position: fixed;
    top: 0;
    left: calc(50% - 80px);
    margin-top: 50px;
    z-index: 10;
}

.nav_mobile .menu_mobile {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* text-align: left; */
}

.nav_mobile .menu_item {
    font-family: "Givonic", sans-serif;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    display: block;
    letter-spacing: 4px;
    padding-top: 15px;
    transition: .3s cubic-bezier(0, -0, .3, 2);
    transform: translate3d(0, 0, 0);
}

.nav_mobile .menu_item:hover {
    transform: translate3d(15px, 0, 0);
}

.menu_mobile:hover li>*:not(:hover) {
    text-decoration: line-through;
}


/* Responsive */
@media only screen and (max-width: 1100px) {
    .invert_icons {
        transform: scale(.7);
        margin: 0;
    }

    .invert_wrap {
        top: -5px;
        right: 10px;
    }

    .nav_mobile .logo {
        margin-top: 25px;
    }

    .top_settings {
        top: 20px;
    }

    .menu_bar {
        top: 15px;
    }

    .logo-img {
        max-width: 120px;
    }

    .breadcrumb_item {
        font-size: 12px;
    }

    .bottom_settings {
        top: calc(100vh - 20px);
    }

    .scroll_down {
        top: calc(100vh - 20px);
    }

}

@media only screen and (max-width: 1024px) {

    .nav:before,
    .nav_mobile:before {
        height: 100px;
    }

    .scroll_down {
        top: calc(100vh - 80px);
    }

    .breadcrumb {
        display: none;
    }

    .nav_mobile .logo {
        left: calc(50% - 60px);
    }
}


@keyframes colorSlide {
    0% {
        background-position: 0% 100%;
    }

    20% {
        background-position: 0% 0%;
    }

    21% {
        background-color: #4e5559;
    }

    29.99% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    30% {
        background-color: #4e5559;
        background-position: 0% 100%;
    }

    50% {
        background-position: 0% 0%;
    }

    51% {
        background-color: #4e5559;
    }

    59% {
        background-color: #ffffff;
        background-position: 0% 0%;
    }

    60% {
        background-color: #4e5559;
        background-position: 0% 100%;
    }

    80% {
        background-position: 0% 0%;
    }

    81% {
        background-color: #4e5559;
    }

    90%,
    100% {
        background-color: #ffffff;
    }
}

@keyframes trackBallSlide {
    0% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }

    6% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    14% {
        opacity: 0;
        transform: scale(0.4) translateY(10px);
    }

    15%,
    19% {
        opacity: 0;
        transform: scale(0.4) translateY(-5px);
    }

    28%,
    29.99% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }

    30% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }

    36% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    44% {
        opacity: 0;
        transform: scale(0.4) translateY(10px);
    }

    45%,
    49% {
        opacity: 0;
        transform: scale(0.4) translateY(-5px);
    }

    58%,
    59.99% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }

    60% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }

    66% {
        opacity: 1;
        transform: scale(0.9) translateY(5px);
    }

    74% {
        opacity: 0;
        transform: scale(0.4) translateY(10px);
    }

    75%,
    79% {
        opacity: 0;
        transform: scale(0.4) translateY(-5px);
    }

    88%,
    100% {
        opacity: 1;
        transform: scale(1) translateY(-5px);
    }
}

@keyframes nudgeMouse {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(5px);
    }

    30% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(5px);
    }

    60% {
        transform: translateY(0);
    }

    80% {
        transform: translateY(5px);
    }

    90% {
        transform: translateY(0);
    }
}