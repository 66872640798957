.preloader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    /* animation: fadeOut 1s ease-in-out 2.4s 1; */
}

.preloader_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.left_bracket, .right_bracket{
	transition: 1s ease;
    height: 60px;
    fill: #fff;
}
.logo_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-49%,-50%);
    height: 40px;
    width: 205px;
    fill: #fff;
}
/* .right_bracket{
    transform: translateX(-100px);
    animation: leftToRight 3.5s ease-in-out .2s 1;
}
.left_bracket{
    transform: translate(100px, 0px);
    animation: rightToLeft 3.5s ease-in-out .2s 1;
} */

.preloader_container .main_logo{
    overflow: hidden;
    position: relative;
    height: 40px;
    width: 0;
    /* vertical-align: middle;
    visibility: hidden;
    clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%); */
    animation: reveal 3.8s ease-in-out .2s 1;
}

/* @keyframes leftToRight {
    0%{
        transform: translateX(-100px);
    }
    40%{
        transform: translateX(7px);
    }
    100%{
        transform: translateX(7px);
    }
}

@keyframes rightToLeft {
    0%{
        transform: translateX(100px);
    }
    40%{
        transform: translateX(-10px);
    }
    100%{
        transform: translateX(-10px);
    }
} */

@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    40%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes reveal {
    0%{
        width: 0;
    }
    40%{
        width: 222px;
    }
    100%{
        width: 222px;
    }
}