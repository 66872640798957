.work {
    background-image: url(../../images/work.jpg);
    background-size: 100% 100%;
    padding-top: 150px;
    /* min-width: 100vw; */
    min-height: 100vh;
    position: relative;
}

.work_container {
    display: flex;
}

.workLoading {
    max-width: 450px;
    height: 50px;
    position: relative;
    overflow: hidden;
}

.workLoading:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNC40OSA4MS42MyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8xLTIiPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjE3LjM0IiBoZWlnaHQ9IjgxLjYzIi8+PHJlY3QgY2xhc3M9ImNscy0yIiB4PSIxNy4zNCIgd2lkdGg9IjcuMTUiIGhlaWdodD0iODEuNjMiLz48L2c+PC9zdmc+);
    background-repeat: repeat-x;
    animation: loading_animation 7s linear infinite;
}

.work_loading_text {
    font-family: 'PT Mono', monospace;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 5px;
    color: #fff;
    text-align: center;
}

.work_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
    width: 100%;
}

.work_single {
    max-width: 405px;
    width: 100%;
    text-align: center;
}

.work_dubble {
    position: relative;
    width: 100%;
}


.ww_thubnail {
    max-width: 200px;
    margin-top: 40px;
}

.work_single_title {
    font-family: "normalidad-ultraextended", sans-serif;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 30px;
    text-decoration: none;
    margin-top: 30px;
}

.work_single_paragraph {
    font-family: "Givonic", sans-serif;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 20px;
    text-decoration: none;
    margin: 0;
    margin-top: 25px;
    /* max-width: 400px; */
    line-height: 30px;
}

.section_link {
    text-decoration: none;
}

.work_single_link {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-family: "normalidad-ultraextended", sans-serif;
    margin: 0;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    margin-top: 25px;
}

@keyframes loading_animation {
    0% {
        background-position: 0px;
    }

    100% {
        background-position: 330px;
    }
}

@media only screen and (max-width: 1100px) {
    .work_loading {
        padding: 0 20%;
    }

    .work_single {
        max-width: inherit;
    }
}

@media only screen and (max-width: 768px) {
    .work {
        background-size: auto 80%;
        background-position: bottom center;
        background-repeat: no-repeat;
    }

    .workLoading {
        /* width: 200px; */
        /* margin: 0 auto; */
        height: 30px;
    }

    .work_container {
        display: block;
    }

    .work_loading {
        position: static;
        /* top: 50%; */
        /* left: 50%; */
        transform: inherit;
        max-width: 450px;
        width: 100%;
        margin: auto;
        margin-top: 10%;
        padding: 0 15%;
    }
}

@media only screen and (max-width: 550px) {
    .work_loading {
        margin-top: 15%;
    }
}